<template>
    <div class="container py-5">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/dashboard">Admin Dashboard</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Collaborators</li>
            </ol>
        </nav>
        <div class="row justify-content-between">
            <div class="col-4">
                <h1>Collaborators</h1>
            </div>
            <div class="col-4 d-md-flex justify-content-md-end">
                <div>
                    <router-link to="admincollaborator" class="btn btn-success mx-1">Add</router-link>
                </div>
            </div>
        </div>

        <div class="row">
            <div v-if="!loaded" class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div v-else class="list-group list-group-numbered">
                <a href="#" v-for="item in collaborators" :key="item.id" class="list-group-item list-group-item-action">
                    <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">{{item.name}}</h5>

                        <div>
                            <router-link :to="{ path: 'admincollaborator', query: { id: item.id } }" class="btn btn-warning mx-1">Edit</router-link>
                            <button @click="openDeleteModal(item)" type="button" data-bs-toggle="modal" data-bs-target="#dialogYesNo" class="btn btn-danger mx-1">Delete</button>
                        </div>
                    </div>
                    <small class="text-muted">{{item.department}}</small>
                    <p class="mb-1">{{item.organization}}</p>
                </a>
            </div>
        </div>
        <div class="modal" id="dialogYesNo" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Skin Atlas</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p>Are you sure you want to delete item: {{selectedItem.name}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" @click="deleteItem(selectedItem)" data-bs-dismiss="modal" class="btn btn-danger"> Yes </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "axios";
    import { authHeader } from '@/services/authHeader.js';
    import { toastOptions } from '@/helpers/toastOptions.js';

    let selectedItem = {name:""};
    let collaborators = [];
    let loaded = false;
    const options = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    export default {
        name: 'AdminCollaborator',
        data() {
            return {
                selectedItem : selectedItem,
                collaborators: collaborators,
                loaded: loaded
            }
        },
        methods: {
            openDeleteModal(item) {
                this.selectedItem = item;
            },
            deleteItem(item) {
                    axios.delete(process.env.VUE_APP_URL + `api/collaborators/${item.id}`, options)
                    .then(() => {
                        this.$toast("Item deleted", toastOptions.success);
                        this.getData();
                    })
                    .catch(error => {
                        this.$toast(error.message, toastOptions.error);
                    })
            },
            getData() {
                axios.get(process.env.VUE_APP_URL + "api/collaborators")
                    .then(response => {
                        this.loaded = true;
                        this.collaborators = response.data;
                    })
                    .catch(error => {
                        this.$toast(error.message, toastOptions.error);
                    })
            }
        },
        mounted() {
            this.getData();
        }
    }
</script>
